<template>
    <div class="col-right">
        <a-card title="银行帐户">
            <div class="row-info">帐户名：蚂蚁换呗（广州）科技有限公司</div>
            <div class="bank-list ng-star-inserted">
                <ul>
                    <li v-for="item in bankList" :key="item.id" class="ng-star-inserted">
                        <div class="item-left">
                            <a-avatar size="large" :src="item.bank_icon"/>
                        </div>
                        <div class="item-right">
                            <dl>
                                <dt>{{item.bank_card_no}}</dt>
                                <dd>{{item.bank_branch_name}}</dd>
                            </dl>
                        </div>

                    </li>
                    <li class="ng-star-inserted" @click="() => {modal1Visible = true}">
                        <div class="item-btn">
                            <i></i><span>添加银行账户</span>
                        </div>
                    </li>
                </ul>
            </div>
        </a-card>
        <a-modal
                :dialog-style="{ top: '100px' }"
                :visible="modal1Visible"
                :footer="false"
                @cancel="() => {modal1Visible = false}"
        >
            <h2 class="text-center">添加银行帐户</h2>
            <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" @submit="handleSubmit">
                <a-form-item label="帐户名">
                    <div>蚂蚁换呗（广州）科技有限公司</div>
                </a-form-item>
                <a-form-item label="银行卡号">
                    <a-input
                            v-decorator="['bank_card_no', { rules: rules.bank_card_no}]"
                    />
                </a-form-item>
                <a-form-item label="开户银行">
                    <a-select
                            v-decorator="[
                  'bank_name',
                  { rules: rules.bank_name },
                ]"
                            placeholder="请选择"
                    >
                        <a-select-option v-for="(item, idx) in card_type_list" :value="item" :key="idx">
                            {{item}}
                        </a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label="开户地区">
                    <a-cascader :options="region"
                                placeholder="请选择"
                                v-decorator="['bankArea', { rules: rules.bank_area}]"
                    ></a-cascader>
                </a-form-item>
                <a-form-item label="开户支行">
                    <a-input
                            placeholder="请输入开户支行"
                            v-decorator="['bank_branch_name', { rules: rules.bank_branch_name}]"
                    />
                </a-form-item>
                <a-form-item label="验证码">
                    <a-input-search placeholder="请输入验证码" size="large" @search="getVerifyCode(7)"
                                    v-decorator="['verify_code', {rules: rules.verify_code}]"
                    >
                        <a-button slot="enterButton" :disabled="!canGetVerifyCode">
                            {{getVerifyCodeText}}
                        </a-button>
                    </a-input-search>
                </a-form-item>

                <a-form-item>
                    <a-row>
                        <a-col :span="24" :style="{ textAlign: 'right' }">
                            <a-button @click="() => {modal1Visible = false;}">关闭</a-button>
                            <a-button :style="{ marginLeft: '8px' }" type="danger" html-type="submit">
                                保存
                            </a-button>
                        </a-col>
                    </a-row>

                </a-form-item>
            </a-form>
        </a-modal>
    </div>
</template>

<script>
    import service from "../../../utils/request";

    export default {
        name: "property-bank",
        data() {
            return {
                userInfo:JSON.parse(localStorage.getItem('userInfo')),
                bankList: [
                    // {
                    //     id: 1,
                    //     bank_icon: 'https://scce-cos-obs.obs.cn-south-1.myhuaweicloud.com/330e7686a8994e538cda9617aa71fea5.png',
                    //     card_num: '44050147004209188401',
                    //     bank_sub_name: '中国建设银行股份有限公司广州萝岗支行'
                    // }
                ],
                modal1Visible: false,
                form: this.$form.createForm(this, {name: 'coordinated'}),
                rules: {
                    bank_card_no: [
                        {required: true, message: '请输入银行卡号', trigger: 'blur'},
                    ],
                    bank_name: [{required: true, message: '请输入帐户名', trigger: 'change'}],
                    bank_branch_name: [{required: true, message: '请输入开户行支行名称', trigger: 'change'}],
                    bank_area: [{type: 'array', required: true, message: '请选择', trigger: 'change'}],
                    verify_code: [{required: true, message: '请输入验证码', trigger: 'change'}],
                },
                card_type_list: [
                    '中国工商银行',
                    '中国银行',
                    '中国建设银行',
                    '中国农业银行',
                    '招商银行',
                    '中国邮政储蓄银行',
                    '中国光大银行',
                    '上海浦东发展银行',
                    '兴业银行',
                    '平安银行',
                    '广东发展银行',
                    '中信银行',
                    '交通银行',
                    '中国民生银行',
                    '杭州银行',
                    '苏州银行',
                ],
                region: [],
                getVerifyCodeText: '获取验证码',
                canGetVerifyCode: true,
                countDown: 60
            }

        },
        created() {
            this.getRegion();
            this.getList();
        },
        methods: {
            getList(){
                service.post(service.uri.bankAccount.getList).then(res => {
                    if(res.code == 200){
                        this.bankList = res.data.list;
                    }
                });
            },
            handleSubmit(e) {
                e.preventDefault();
                this.form.validateFields((err, values) => {
                    console.log(values)
                    if (!err) {
                        console.log('Received values of form: ', values);
                        service.post(service.uri.bankAccount.add, values).then(res => {
                            if(res.code == 200){
                                this.modal1Visible = false;
                                this.getList();
                            }
                        });
                    }
                });
            },
            changeRegion(e) {
                console.log(e)
                console.log(this.form)
            },
            getRegion(){
                service.post(service.uri.bankAccount.getRegion).then(res => {
                    if(res.code == 200){
                        this.region = res.data;
                    }
                });
            },
            getVerifyCode(type) {
                this.canGetVerifyCode = false;
                if(this.countDown < 60) return false;
                service.post(service.uri.user.getVerifyCode, {phone: this.userInfo.mobile, type: type}).then(() => {

                    this.timer = setInterval(() => {
                        this.countDown--;
                        if(this.countDown <= 0){
                            this.getVerifyCodeText = '获取验证码';
                            this.countDown = 60;
                            this.canGetVerifyCode = true;
                            clearInterval(this.timer);
                            return ;
                        }
                        this.getVerifyCodeText = '请等待'+this.countDown+'S';
                    }, 1000);
                });

            }
        }
    }
</script>

<style lang="scss" scoped>
    .row-info {
        padding: 20px 0;
        color: #666;
    }
    .bank-list {
        li {
            display: flex;
            width: 356px;
            height: 95px;
            background: #fafafa;
            align-items: center;
            padding: 0 20px;
            float: left;
            margin: 0 20px 20px 0;

            .item-left {
                width: 46px;
                height: 46px;
            }

            .item-right {
                padding-left: 20px;

                dl {
                    dt {
                        font-size: 18px;
                        color: #333;
                        line-height: 24px;
                    }

                    dd {
                        padding-top: 10px;
                        color: #666;
                        line-height: 20px;
                    }
                }
            }

            .item-btn {
                flex: 1;

                i {
                    display: block;
                    margin: 0 auto;
                    background: url('../../assets/img/icons/icon-plus.png');
                    width: 28px;
                    height: 28px;
                }

                span {
                    display: block;
                    text-align: center;
                    padding-top: 10px;
                    color: #666;
                }
            }
        }
    }

    .ant-input-affix-wrapper {
        padding-top: 4px;
        padding-bottom: 4px;
    }
</style>